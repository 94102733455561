<template>
  <div class="tab-pane active" id="rcs-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <SentChart />
        </div>
        <div class="col-lg">
          <div class="row enviadas stats-column-right pr-5">
            <div class="card card-messages card-second-row inner-card">
              <div class="card-body">
                <Fail />
                <p class="card-text">Falhas</p>
                <span class="card-text">0</span>
              </div>
            </div>
            <div class="card card-messages card-second-row-2 inner-card">
              <div class="card-body">
                <Tarifada />
                <p class="card-text">Tarifadas</p>
                <span class="card-text">100Mil</span>
              </div>
            </div>
            <div
              id="infos-top"
              class="card card-messages"
            >
              <div class="card-body">
                <LottieAnimMsgGreen />
                <p class="card-text">Mensagem Enviadas</p>
                <span class="card-text">249Mil</span>
              </div>
            </div>
          </div>
          <div class="row recebidas stats-column-right pr-5">
            <div class="card card-messages card-second-row inner-card">
              <div class="card-body">
                <Fail />
                <p class="card-text">Opt-Outs</p>
                <span class="card-text">0</span>
              </div>
            </div>
            <div class="card card-messages card-second-row-2 inner-card">
              <div class="card-body">
                <Check />
                <p class="card-text">Recebidas</p>
                <span class="card-text">100Mil</span>
              </div>
            </div>
            <div
              id="infos-bottom"
              class="card card-messages"
            >
              <div class="card-body">
                <LottieAnimMsgPurple />
                <p class="card-text">Mensagem Recebidas</p>
                <span class="card-text">249Mil</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <StatusChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import SentChart from '@/components/rebranding/apexcharts/SentChart.vue';
import StatusChart from '@/components/rebranding/apexcharts/StatusChart.vue';
import LottieAnimMsgGreen from '@/components/rebranding/animLottie/MsgGreen.vue';
import LottieAnimMsgPurple from '@/components/rebranding/animLottie/MsgPurple.vue';
import Fail from '@/components/rebranding/animLottie/Fail.vue';
import Check from '@/components/rebranding/animLottie/Check.vue';
import Tarifada from '@/components/rebranding/animLottie/Tarifada.vue';
import $ from 'jquery';

export default {
  name: 'RCSStats',
  components: {
    SentChart,
    StatusChart,
    LottieAnimMsgGreen,
    LottieAnimMsgPurple,
    Fail,
    Check,
    Tarifada,
  },
  directives: {
    Tab,
  },
  /* mounted() {
    setTimeout(() => {
      $('#infos-top').on('click', () => {
        $('.enviadas .card-second-row').toggleClass('active');
        $('.enviadas .card-second-row-2').toggleClass('active');
      });
      $('#infos-bottom').on('click', () => {
        $('.recebidas .card-second-row').toggleClass('active');
        $('.recebidas .card-second-row-2').toggleClass('active');
      });
    }, 300);
  }, */
};
</script>

<style lang="scss" scoped>
.stats-column-right {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
}
.card-messages {
  /* margin-left: auto; */
  max-height: 230px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--clr-light-2);
      font-weight: bold;
    }
  }
}
#infos-bottom,
#infos-top {
  position: relative;
  z-index: 1;
  min-width: 260px;
}
.card-messages.inner-card {
  /* position: absolute;
  right: 0; */
  /* opacity: 0; */
  z-index: 1;
}
.card-messages.inner-card.active {
  /* position: absolute;
  right: 325px; */
  z-index: 0;
  /* opacity: 1; */
}
/* .card-messages.inner-card.active:last-of-type {
  right: 470px;
} */

.darkmode .card {
  background-color: var(--background-3);
}
</style>
