<template>
  <div class="chart-wrapper">
    <div class="chart-opt">
      <span>{{ $t('sms.infos.tariffed-component.title') }}</span>
      <span
        class="material-symbols-outlined icon_opt"
        :class="[chartTypeLine === true ? 'active' : '']"
        @click="toggleLineChart"
      >
        show_chart
      </span>
      <span
        class="material-symbols-outlined icon_opt"
        :class="[chartTypePie === true ? 'active' : '']"
        @click="togglePieCHart"
      >
        data_usage
      </span>
    </div>
    <div class="chart" :class="[chartTypeLine === true ? '' : 'd-none']">
      <apexchart
        ref="lineChart"
        height="500"
        :options="line"
        :series="series_line"
      ></apexchart>
    </div>
    <div class="chart" :class="[chartTypePie === true ? '' : 'd-none']">
      <apexchart
        ref="pieChart"
        height="500"
        :options="pie"
        :series="series_pie"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'SentChart',
  data() {
    return {
      line: {
        chart: { type: 'line' },
        /* series: [
            { name: '2017', type: 'line', data: [10, 20] },
            { name: '2018', type: 'line', data: [15, 25] },
          ], */
        /* labels: ['from', 'to'],
          stroke: { width: 5 }, */
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        markers: {
          size: 5,
        },
      },
      series_line: [],
      pie: {
        chart: { type: 'donut' },
        labels: ['TIM', 'Oi', 'Claro', 'Vivo', 'Nextel'],
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            donut: {
              size: '55%',
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        },
      },
      series_pie: [41, 100, 19, 18, 81],
      stroke: {
        curve: 'straight',
      },
      chartTypeLine: true,
      chartTypePie: false,
      form: {
        start_date: moment()
          .subtract(24, 'hours')
          .format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        time_window: 'hour',
        group_by: 'carrier',
      },
    };
  },
  watch() {
    this.$refs.pieChart.render();
    this.$refs.lineChart.render();
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchSmsInsights', this.form).then((response) => {
        const categories = [];
        response.forEach((insight) => {
          const series = {
            name: insight.group_by,
            data: insight.values,
          };
          this.series_line.push(series);
        });
        // this.series_line = Object.values(series);
        this.loading = false;
      });
    },
    toggleLineChart() {
      this.chartTypePie = false;
      this.chartTypeLine = true;
    },
    togglePieCHart() {
      this.chartTypeLine = false;
      this.chartTypePie = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}
</style>
