const tab = {
  bind(el, binding) {
    el.addEventListener('click', (e) => {
      const parent = el.parentElement.parentElement;
      parent.getElementsByClassName('nav-link').forEach((element) => {
        element.classList.remove('active');
      });
      el.classList.add('active');
      const panes = document.getElementsByClassName('tab-pane');

      panes.forEach((element) => {
        element.classList.remove('active');
      });
      document.getElementById(binding.value).classList.add('active');
      e.stopPropagation();
    });
  },
};

export default tab;
